import "./App.css";
import "react-toastify/dist/ReactToastify.css";
import { BrowserRouter } from "react-router-dom";
import "./styles/globals.css";
import "./styles/checkoutPage.css";
import { Spinner } from "react-bootstrap";
import Routes from "./views/Routes/Routes";
import { useSelector, useDispatch } from "react-redux";
import { useEffect, useState } from "react";
import { useFetchMerchantMutation, useFetchSubscriptionsMutation } from "./store/services/smApi";
import { useCheckoutAutoLoginDetailsMutation, useFetchUserMutation } from "./store/services/authApi";
import { removeQueryParam } from "./utils/useQueryParams";
import { SiteHelmet } from "./utils/Helmet";
import { setIsCheckingAutoLogin } from "./store/slices/userSlice";
import LoadingSpinner from "./components/LoadingSpinner";

function App () {
  const searchParams = new URLSearchParams(location.search);
  const dispatch = useDispatch();

  const isLoggedIn = useSelector((state) => state.userStore.isLoggedIn);
  const merchant = useSelector((state) => state.configStore.merchant);
  const [noTenant, setNoTenant] = useState(null);
  const [fetchMerchant, { isLoading: isLoadingMerchant }] =
		useFetchMerchantMutation();
  const [fetchSubscriptions, { isLoading: isLoadingSubscriptions }] =
		useFetchSubscriptionsMutation();
  const [autoLoginDetailsApi] = useCheckoutAutoLoginDetailsMutation();

  const [fetchUser, { isLoading: isLoadingUser }] = useFetchUserMutation();
  const autoLoginToken = searchParams.get("auto_login_token");
  const isAutoLogin = () => (autoLoginToken);
  const handleAutoLogin = async () => {
    const token = searchParams.get("auto_login_token");
    removeQueryParam("auto_login_token");
    dispatch(setIsCheckingAutoLogin(true));
    await autoLoginDetailsApi({ token });
    dispatch(setIsCheckingAutoLogin(false));
  };

  const handleNormalData = async () => {
    await fetchSubscriptions();
    const result = await fetchMerchant();
    if (result.error) {
      setNoTenant(true);
    } else {
      setNoTenant(false);
      if (isAutoLogin) {
        await handleAutoLogin();
      } else {
        await fetchUser();
      }
    }
  };
  useEffect(() => {
    handleNormalData();
  }, []);
  useEffect(() => {
    if (!merchant) return;
    const root = document.querySelector(":root");
    const RGP = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(merchant.primary_color);
    root.style.setProperty("--bs-shadow", `rgba(${parseInt(RGP[1], 16)}, ${parseInt(RGP[2], 16)}, ${parseInt(RGP[3], 16)}, 0.35`);
    root.style.setProperty("--bs-first", merchant.primary_color);
    root.style.setProperty("--bs-second", merchant.primary_color);
  }, [merchant]);
  if ((!merchant || isLoadingSubscriptions || isLoadingMerchant | isLoadingUser) && noTenant === null) {
    return <div className="text-center"><Spinner animation="border" variant={"success"}/></div>;
  }
  if (autoLoginToken) return <LoadingSpinner />;

  return (
    <>
      {noTenant === false &&
        <BrowserRouter>
          {merchant && <SiteHelmet merchant={merchant}/>}
          <Routes isAuthenticated={isLoggedIn}/>
        </BrowserRouter>
      }
      {noTenant === true && !isLoadingMerchant &&
        <>
          <SiteHelmet merchant={{}} />
          <div className="text-center">No merchant found on given url</div>
        </>}
    </>
  );
}

export default App;
